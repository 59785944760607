<template>
  <v-card class="mx-auto" height="auto">
    <center style="margin-top: 25px; font-size: 24px">Data Extraction</center>
    <v-divider></v-divider>

    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          label="Search"
          style="margin-top: 12px; margin-left: 8px"
          outlined
          dense
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-menu
          class="text-center"
          content-class="elevation-0"
          :close-oncontent-click="false"
          transition="scale-transition"
          offet-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="DateFrom"
              v-bind="attrs"
              v-on="on"
              color="red"
              label="Date From"
              style="margin-top: 12px"
              filled
              outlined
              dense
            />
          </template>
          <v-date-picker v-model="DateFrom" color="#df6464" />
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-menu
          class="text-center"
          content-class="elevation-0"
          :close-oncontent-click="false"
          transition="scale-transition"
          offet-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="DateTo"
              v-bind="attrs"
              v-on="on"
              color="red"
              label="Date To"
              style="margin-top: 12px"
              filled
              outlined
              dense
            />
          </template>
          <v-date-picker v-model="DateTo" color="#df6464" />
        </v-menu>
      </v-col>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            style="margin-top: 25px; margin-right: 25px"
            color="#808080"
            class="extract black--text"
            elevation="1"
            outlined
            @click="GetDataExtraction()"
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
        </template>
        <span>Load</span>
      </v-tooltip>
      <v-spacer />

      <v-btn
        style="margin-top: 25px; margin-right: 25px"
        color="#808080"
        class="extract black--text"
        elevation="1"
        outlined
        @click="ExtractData()"
        ><v-icon>mdi-download</v-icon>Extract</v-btn
      >
    </v-row>

    <v-divider style="margin-top: -25px"></v-divider>

    <!-- FOR DATA TABLE -->
    <v-data-table
      :search="search"
      :headers="headers"
      :items="ExtractedData"
      style="margin-top: 15px"
      height="55vh"
    >
    </v-data-table>
    <!-- END OF DATA TABLE -->
  </v-card>
</template>
<script>
// import json2xls from "json2xls";
import moment from "moment";
// import  fs from "fs"
import keys from "../../functions/alert";
import axios from "axios";
import { Extraction } from "../../functions/dataExtraction";
const baseUrl = keys.auth.BaseUrl;
const apikey = keys.auth.apiKey;
export default {
  data: () => ({
    search: "",
    DateFrom: "",
    DateTo: "",
    ExtractedData: [],
    headers: [
      { text: "Sent Date", value: "ReceivedDate" },
      { text: "Customer Code", value: "CustomerCode" },
      { text: "Sender Name", value: "SenderName" },
      { text: "Department", value: "Department" },
      { text: "Handle Email", value: "HandleEmail" },
      { text: "Handle Staff", value: "HandleStaff" },
      { text: "SubHandle Staff", value: "SubHandleStaff" },
      { text: "Inquiry Kind", value: "InquiryKind" },
      { text: "Job Kind", value: "JobKind" },
      { text: "Subject", value: "Subject" },
      { text: "Content of Inquiry", value: "ContentOfInquiry" },
    ],
    dateToday: moment().format("YYYY-MM-DD"),
    filter: {},
  }),

  methods: {
    async ExtractData() {
      if (!this.DateFrom) {
        alert("Please Input Date");
        return false;
      }
      if (!this.DateTo) {
        alert("Please Input Date");
        return false;
      }
      let x = new Extraction();
      const asyncFunctions = [
        x.printReports(
          `${baseUrl}/getDataExtraction/${this.DateFrom}/${this.DateTo}`,
          apikey
        ),
      ];
      await Promise.race(asyncFunctions);
    },

    GetDataExtraction() {
      if (!this.DateFrom) {
        alert("Please Input Date");
        return false;
      }
      if (!this.DateTo) {
        alert("Please Input Date");
        return false;
      }
      axios
        .get(`${baseUrl}/getDataExtraction/${this.DateFrom}/${this.DateTo}`, apikey)
        .then((res) => {
          res.data.filter((rec) => {
            rec.ReceivedDate = rec.ReceivedDate
              ? moment(rec.ReceivedDate).format("YYYY-MM-DD")
              : "";
            rec.ContentOfInquiry = rec.ContentOfInquiry.split("<br>").join(" ");
          });
          this.ExtractedData = Object.assign(res.data);
        });
    },
  },
};
</script>
<style>
.extract.v-btn--outlined {
  border: thin solid #808080;
}
</style>
